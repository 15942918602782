// gpt-response-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-gpt-response-dialog',
  templateUrl: './gpt-response-dialog.component.html',
  styleUrls: ['./gpt-response-dialog.component.scss']
})
export class GptResponseDialogComponent {
  public canReplace: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<GptResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      response: string,
      selectedTextLength: number,
    }
  ) {
    this.data.response = this.data.response.replace(/\n/g, '<br />');
    this.canReplace = this.data.selectedTextLength > 0;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  replaceSelectedText(): void {
    this.dialogRef.close({ action: 'replace', response: this.data.response });
  }

  appendToText(): void {
    this.dialogRef.close({ action: 'append', response: this.data.response });
  }
}
