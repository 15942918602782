<!-- gpt-response-dialog.component.html -->
<div cdkDrag>
  <h1 mat-dialog-title>GPT Response</h1>
  <mat-dialog-content>
    <p [innerHTML]="data.response"></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button *ngIf="canReplace" (click)="replaceSelectedText()">Replace Selected Text</button>
    <button mat-button (click)="appendToText()">Append to Text</button>
  </mat-dialog-actions>
</div>
